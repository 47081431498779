export const btn =
  "bg-gray-100 hover:bg-gray-200 px-3 py-2 rounded cursor-pointer overflow-hidden flex items-center shrink-0 truncate disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-gray-100";
export const btnActive = "bg-gray-200";

export const sidebarBtn =
  "hover:bg-gray-300 border-solid border-1 ml-2 px-2.5 py-1.5 rounded cursor-pointer overflow-hidden flex items-center shrink-0 truncate";

export const sidebarBtnActive = "bg-gray-300 font-semibold";

export const newBtn = "mt-2 mb-2 pl-1 pr-1 flex items-center rounded";
