import { db } from "@/db";
import { createNode, deleteNode } from "@/utils";
import { generateNKeysBetween } from "fractional-indexing";
import { Maybe } from "graphql/jsutils/Maybe";
import { getNodeChildren } from "./genericUtils";
import { EfNode } from "@/types";
import { ClipboardEfNode } from "@/utils/copy";
import { useHistoryManager } from "@/hooks/useHistoryManager";

export const onPaste =
  (
    history: ReturnType<typeof useHistoryManager>,
    node: EfNode,
    setCurrentId: (id: string) => void
  ) =>
  (copiedNodes: ClipboardEfNode[]) => {
    history.run({
      redo: async () => {
        await db.transaction("rw", db.nodes, async () => {
          if (!node.parentId) return;
          // Get the nodes children
          const children = await getNodeChildren(node.id);
          // If node doesn't have children we will paste as sibling otherwise we will paste as child
          const pasteAsSibling = !children.length;

          let rootParentID: string | undefined; // The node that will be parent of the pasted nodes
          let startPosition: Maybe<string> | undefined; // The position that we will start pasting from
          let endPosition: Maybe<string> | undefined; // The position we will stop pasting at

          if (pasteAsSibling) {
            // Paste as sibling
            rootParentID = node.parentId; // Parent ID is same as the parent of the node we are pasting into
            const siblings = await getNodeChildren(node.parentId);
            const indexInSiblings = siblings.findIndex(
              (sibling) => sibling.id === node.id
            );
            const nextSibling = siblings[indexInSiblings + 1];
            startPosition = node.position; // Start position is the the position of the node we are pasting into
            endPosition = nextSibling?.position; // End position is the position of current nodes sibling
          } else {
            // Paste as child
            rootParentID = node.id; // Parent ID is the node we are pasting into
            startPosition = undefined; // We will paste as first child so start position is empty
            endPosition = children[0]?.position; // End position is the position of the first child
          }

          const rootNodes = copiedNodes.filter((node) => node.isRoot);
          const rootNodePositions = generateNKeysBetween(
            startPosition,
            endPosition,
            rootNodes.length
          );

          let rootIndex = 0;
          for (const copiedNode of copiedNodes) {
            const isLast = copiedNode === copiedNodes[copiedNodes.length - 1];
            let position = copiedNode.position;
            let parentID = copiedNode.parentId;
            if (copiedNode.isRoot) {
              position = rootNodePositions[rootIndex];
              parentID = rootParentID; // If this is a root node we want parent ID to be root node
              rootIndex++;
            }
            await createNode({
              ...copiedNode,
              parentId: parentID,
              position,
            });
            if (isLast) setCurrentId(copiedNode.id);
          }
        });
      },
      undo: async () => {
        await db.transaction("rw", db.nodes, async () => {
          await Promise.all(copiedNodes.map(deleteNode));
          setCurrentId(node.id);
        });
      },
    });
  };
