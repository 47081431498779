import { Editor } from "@tiptap/react";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isMacOs } from "react-device-detect";
import clsx from "clsx";
import { uploadFileCommand } from "../../utils/uploadFileCommand";
import { ListComponent } from "./List";
import { findElementWithNodeId } from "@/utils/findElementWithNodeId";
import { getNodeIdFromEditorId } from "../VirtualizedEditor/editorUtils/genericUtils";
import { useAreaSelection } from "../SelectionWrapper/SelectionWrapper";
import { getSelectedNodes } from "../SelectionWrapper/selectionUtils";

export function EditorContextMenuWrapper({
  children,
  nodeId,
  setNodeId,
  areaSelection,
}: {
  children: ReactNode;
  nodeId?: string;
  setNodeId: React.Dispatch<React.SetStateAction<string | undefined>>;
  areaSelection: ReturnType<typeof useAreaSelection>;
}) {
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [contextMenu, setContextMenu] = useState(false);
  const [editor, setEditor] = useState<Editor>();
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    left: number;
    top: number;
  }>({
    left: 0,
    top: 0,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedButtonRef = useRef<HTMLButtonElement | null>(null);
  const executeCommand = async (command: (editor?: Editor) => void) => {
    // Exec a buttons command
    const isAreaSelectionActive = areaSelection.isAreaSelectionActive();
    // If area selection is active this means we have to get all selected nodes, and trigger the command on each of them
    if (isAreaSelectionActive) {
      const selectedNodes = await getSelectedNodes(
        areaSelection.areaSelectedBounds
      );
      const editors = selectedNodes.map((node) => window.editors?.[node.id]);
      editors.forEach(command);
    } else {
      // If no area selection is active, we can just trigger the command on the current editor
      command(editor);
    }
  };

  const buttons = useMemo(() => {
    const btn = [
      {
        key: "task",
        label: "Task",
        // @ts-ignore
        command: (editor?: Editor) => editor?.chain().focus().toggleTask(),
      },
      {
        key: "paragraph",
        label: "Paragraph",
        command: (editor?: Editor) =>
          editor?.chain().focus().setNode("paragraph").run(),
      },
      {
        key: "heading1",
        label: "Heading 1",
        command: (editor?: Editor) =>
          editor?.chain().focus().setNode("heading", { level: 1 }).run(),
      },
      {
        key: "heading2",
        label: "Heading 2",
        command: (editor?: Editor) =>
          editor?.chain().focus().setNode("heading", { level: 2 }).run(),
      },
      {
        key: "heading3",
        label: "Heading 3",
        command: (editor?: Editor) =>
          editor?.chain().focus().setNode("heading", { level: 3 }).run(),
      },
      {
        key: "bulletList",
        label: "Bullet List",
        command: (editor?: Editor) =>
          editor?.chain().focus().toggleBulletList().run(),
      },
      {
        key: "orderedList",
        label: "Ordered List",
        command: (editor?: Editor) =>
          editor?.chain().focus().toggleOrderedList().run(),
      },
      {
        key: "codeBlock",
        label: "Code block",
        command: (editor?: Editor) =>
          editor?.chain().focus().toggleCodeBlock().run(),
      },
      {
        key: "blockquote",
        label: "Blockquote",
        command: (editor?: Editor) =>
          editor?.chain().focus().toggleBlockquote().run(),
      },
      {
        key: "file",
        label: "File",
        action: async () => {
          editor && uploadFileCommand(editor);
        },
        command: (editor?: Editor) => editor?.chain().focus().run(),
      },
      {
        key: "copyLinkToNode",
        label: "Copy link to this node",
        command: () => {
          navigator.clipboard?.writeText?.(
            `${window.location.origin}${window.location.pathname}?node=${nodeId}`
          );
        },
      },
    ];
    if (isMacOs) {
      btn.push({
        key: "TextView",
        label: "TextView",
        command: (editor?: Editor) => {
          if (!editor || !nodeId) {
            return true;
          }
          window.open(`execfnimgprocess://image?id=${nodeId}&type=efNode`);
          return true;
        },
      });
    }
    return btn;
  }, [editor, nodeId]);

  useEffect(() => {
    selectedButtonRef.current?.scrollIntoView?.({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }, [selectedIndex]);

  useEffect(() => {
    contextMenuRef.current?.focus();
  }, [contextMenu]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!contextMenu) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      switch (event.key) {
        case "Escape": {
          return closeContextMenu();
        }
        case "ArrowUp": {
          return setSelectedIndex(
            (selectedIndex + buttons.length - 1) % buttons.length
          );
        }
        case "ArrowDown": {
          return setSelectedIndex((selectedIndex + 1) % buttons.length);
        }
        case "Enter": {
          return selectItem(selectedIndex);
        }
      }
    };

    // this is declared for the keydown methods to handle ArrowUp, ArrowDown and enter handler
    document.addEventListener("keydown", handleKeyDown, { capture: true });
    return () => {
      document.removeEventListener("keydown", handleKeyDown, { capture: true });
    };
  }, [contextMenu, selectedIndex]);

  const getPositionOfList = useCallback((clientX: number, clientY: number) => {
    const top = clientY + 288 > window.innerHeight ? clientY - 288 : clientY;
    const left = clientX + 360 > window.innerWidth ? clientX - 360 : clientX;
    return {
      top,
      left,
    };
  }, []);

  const onContextMenu = useCallback(
    (evt: any) => {
      evt.preventDefault();
      if (contextMenu) {
        closeContextMenu();
      } else {
        const elementWithNodeId = findElementWithNodeId(evt.target);
        const nodeId = getNodeIdFromEditorId(
          elementWithNodeId?.getAttribute("data-nodeid")
        );

        setContextMenuPosition(getPositionOfList(evt.clientX, evt.clientY));
        setContextMenu(true);
        setEditor(window.editors?.[nodeId || ""]);
        setNodeId(nodeId);
      }
    },
    [contextMenu]
  );

  const closeContextMenu = useCallback(() => {
    setContextMenu(false);
    setEditor(undefined);
    setNodeId(undefined);
  }, []);

  const selectItem = useCallback(
    (index: number) => {
      const item = buttons[index];
      if (item) {
        if (item.action) {
          item.action();
        }
        executeCommand(item.command);
        closeContextMenu();
      }
    },
    [buttons, closeContextMenu]
  );

  const onBlurContextMenu = useCallback(() => {
    closeContextMenu();
  }, [closeContextMenu]);

  return (
    <div onContextMenu={onContextMenu}>
      {children}
      {contextMenu && (
        <ListComponent
          classes={{
            container:
              "z-10 fixed flex flex-col items-start bg-white shadow-2xl w-80 h-72 overflow-auto rounded-md p-1 cursor-pointer text-gray-700",
          }}
          containerStyle={contextMenuPosition}
          ref={contextMenuRef}
          items={buttons.map((button, index) => ({
            key: button.key,
            label: button.label,
            onMouseEnter: () => setSelectedIndex(-1),
            onClick: () => selectItem(index),
            ref: index === selectedIndex ? selectedButtonRef : null,
            className: clsx(
              "w-full rounded-md px-3 py-2 hover:bg-slate-200 break-words",
              {
                ["bg-slate-200"]: index === selectedIndex,
              }
            ),
          }))}
          onBlurCotainer={onBlurContextMenu}
        />
      )}
    </div>
  );
}
