import { db } from "@/db";
import { EfNodeType } from "@/graphql";
import { useHistoryManager } from "@/hooks/useHistoryManager";
import { createNode, deleteNode, updateNode } from "@/utils";
import { generateKeyBetween } from "fractional-indexing";
import { v4 } from "uuid";
import { getNodeChildren } from "./genericUtils";
import { EfContentWithIds, EfNode, EfNodeEditorData } from "@/types";
import { some } from "lodash";

export const onCreate =
  (
    history: ReturnType<typeof useHistoryManager>,
    node: EfNode,
    setCurrentId: (id: string) => void,
    createChildNode?: boolean,
    createNewEmptyNode?: boolean
  ) =>
  /**
   * Creates a node node
   * @param partialExistingNode
   * @param partialNewNode
   */
  async (
    partialExistingNode: EfContentWithIds | null,
    partialNewNode: EfContentWithIds
  ) => {
    const id = v4();
    history.run({
      redo: async () => {
        await db.transaction("rw", db.nodes, async () => {
          const children = await getNodeChildren(node.id);
          // If children are not visible, create a new node as sibling and not as child
          const childrenCollapsed = some(
            children,
            (child) => !child.computed.visible
          );
          if (
            (children.length > 0 && !childrenCollapsed) ||
            !!createChildNode
          ) {
            const firstChild = children[0];
            await processNewNodeCreation(
              {
                id,
                parentId: node.id,
                position: generateKeyBetween(null, firstChild?.position),
                nodeType: EfNodeType.Block,
                properties: {},
                titleText: "",
                ...partialNewNode,
              },
              partialExistingNode && {
                ...node,
                ...partialExistingNode,
              },
              createNewEmptyNode
            );
            setCurrentId(id);

            return;
          }

          if (!node.parentId) return;

          const siblings = await getNodeChildren(node.parentId);
          const indexInSiblings = siblings.findIndex(
            (sibling) => sibling.id === node.id
          );
          const nextSibling = siblings[indexInSiblings + 1];
          await processNewNodeCreation(
            {
              id,
              parentId: node.parentId,
              position: generateKeyBetween(
                node.position,
                nextSibling?.position
              ),
              nodeType: EfNodeType.Block,
              properties: {},
              titleText: "",
              ...partialNewNode,
            },
            partialExistingNode && {
              ...node,
              ...partialExistingNode,
            },
            createNewEmptyNode
          );
          setCurrentId(id);
        });
      },
      undo: async () => {
        await db.transaction("rw", db.nodes, async () => {
          await deleteNode({ id });
          if (partialExistingNode) {
            await updateNode(node);
          }
          setCurrentId(node.id);
        });
      },
    });
  };

const processNewNodeCreation = async (
  newNode: EfNodeEditorData,
  updatedNode: EfNodeEditorData | null,
  createNewEmptyNode?: boolean
) => {
  await createNode({
    ...newNode,
    ...(createNewEmptyNode && {
      contentText: "",
      tagIds: [],
      mentionIds: [],
      referencedPageIds: [],
    }),
  });
  if (!createNewEmptyNode && updatedNode) {
    await updateNode(updatedNode);
  }
};
