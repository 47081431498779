import * as Sentry from "@sentry/browser";
import { generateHTML } from "@tiptap/react";
import { Operation, applyPatch, compare, deepClone } from "fast-json-patch";
import memoize from "fast-memoize";
import { maxBy, omit, sortBy } from "lodash";
import invariant from "tiny-invariant";
import { db } from "../db";
import { Maybe, api } from "../graphql";
import { EfNode, FileNodeData, GeneralSettings } from "../types";
import { calculateDepth } from "./calculateDepth";
import { debugSync } from "./debug";
import { generateJSON } from "./generateJSON";
import { applyPushPatch, applyServerUpdateBulk, getNodeData } from "./sync";
import { defaultNewTipTapExtensions } from "../extensions/new-tiptap";
import { EF_SCHEMA_VERSION } from "../hooks/useAutoSync";
export * from "./sync";

export const memoizedGenerateHTMLForNewTipTap = memoize((input: any) =>
  generateHTML(input, defaultNewTipTapExtensions)
);

export const memoizedGenerateJSONForNewTipTap = memoize((input: any) =>
  generateJSON(input, defaultNewTipTapExtensions)
);

export function calculatePatch(
  oldNodeData: Partial<EfNode>,
  node: EfNode,
  propsToOmit = [
    "computed",
    "properties.fileContentType",
    "properties.fileName",
    "properties.fileUrl",
    "properties.fileDimension",
  ]
) {
  return compare(
    omit(
      {
        ...oldNodeData,
        // need to covert to string because Date object can not be patched
        clientModifiedTime: oldNodeData?.clientModifiedTime?.toISOString(),
      },
      propsToOmit
    ),
    omit(
      {
        ...getNodeData(node),
        // need to covert to string because Date object can not be patched
        clientModifiedTime: node?.clientModifiedTime?.toISOString(),
      },
      propsToOmit
    )
  );
}

export function applyPatchToNode(
  node: Partial<EfNode>,
  clientPatch: Operation[]
) {
  const { newDocument } = applyPatch(deepClone(node), clientPatch);
  if (newDocument.clientModifiedTime) {
    newDocument.clientModifiedTime = new Date(newDocument.clientModifiedTime);
  }
  if (newDocument.modifiedTime) {
    newDocument.modifiedTime = new Date(newDocument.modifiedTime);
  }
  if (newDocument.createdTime) {
    newDocument.createdTime = new Date(newDocument.createdTime);
  }
  return newDocument;
}

export async function getLastClientTimestamp() {
  const settings = (await db.settings.get("general")) as
    | GeneralSettings
    | undefined;
  const lastClientTimestamp = settings?.lastClientTimestamp ?? 0;
  return lastClientTimestamp;
}

export async function setLastClientTimestamp(lastClientTimestamp: number) {
  await db.settings.update("general", { lastClientTimestamp });
}

export async function getLastServerCursor() {
  const settings = (await db.settings.get("general")) as
    | GeneralSettings
    | undefined;
  const lastServerCursor = settings?.lastServerCursor ?? "";
  return lastServerCursor;
}

export async function setLastServerCursor(lastServerCursor: string) {
  await db.settings.update("general", { lastServerCursor });
}

export async function getLocalDBSchemaVersion() {
  const settings = (await db.settings.get("general")) as
    | GeneralSettings
    | undefined;
  const lastServerCursor = settings?.schemaVersion ?? 0;
  return lastServerCursor;
}

export async function setLocalDBSchemaVersion(schemaVersion: number) {
  await db.settings.update("general", { schemaVersion });
}

export async function clearCursors() {
  await db.settings.update("general", { lastServerCursor: "" });
  await db.settings.update("general", { lastClientTimestamp: 0 });
}

// get nodes that were changed after last client timestamp
export async function getNodesToPush() {
  const lastClientTimestamp = await getLastClientTimestamp();

  return db.nodes
    .where("clientTimestamp")
    .above(lastClientTimestamp)
    .limit(BATCH_SIZE)
    .toArray();
}

const BATCH_SIZE = 100;

export async function push() {
  const nodes = await getNodesToPush();
  if (!nodes.length) {
    return;
  }

  debugSync("--> push start");

  let changedNodes: {
    id: string;
    version: Maybe<number>;
    patch: Operation[];
    depth: number;
    clientTimestamp: number;
    workspaceId: Maybe<string>;
  }[] = [];

  // prepare push body
  for (const node of nodes) {
    if (node.oldNodeData === null) {
      const lastClientTimestamp = await getLastClientTimestamp();
      // Adding this log for sentry - since invariant doesn't throw message on prod
      console.error(
        "Node must have oldNodeData",
        JSON.stringify(node, null, 2),
        lastClientTimestamp
      );
    }
    invariant(
      node.oldNodeData !== null,
      `Node must have oldNodeData ${node.id}`
    );
    invariant(node.clientTimestamp !== null, "Node must have clientTimestamp");

    const patch = calculatePatch(node.oldNodeData, node);

    const depth = await calculateDepth(node.id);

    changedNodes.push({
      id: node.id,
      version: node.version,
      patch,
      depth,
      clientTimestamp: node.clientTimestamp,
      workspaceId: node.workspaceId,
    });
  }

  // parents first, children  last
  changedNodes = sortBy(changedNodes, "depth");

  // TODO send data to server

  const result = (
    await api.Push({
      packets: changedNodes.map((item) => ({
        // NOTE id is needed in packet and in patch
        id: item.id,
        patch: JSON.stringify(item.patch),
        version: item.version ?? 0,
        // TODO use Zustand and load from userConfig
        // item.workspaceId
        workspaceId: item.workspaceId,
      })),
    })
  ).data;

  await db.transaction("readwrite", db.nodes, db.settings, async () => {
    await Promise.all(
      result.applyPatches.map((patch) => applyPushPatch(patch, nodes))
    );
    await setLastClientTimestamp(
      maxBy(changedNodes, "clientTimestamp")!.clientTimestamp
    );
  });

  debugSync("--> push done");
}

export async function pull() {
  debugSync("<-- pull start");
  let nodesLeftToPull: number | null = null;
  let pulledNodes = 0;
  const lastServerCursor = await getLastServerCursor();
  const { data: result, headers } = await api.Pull(
    { after: lastServerCursor },
    { "X-Ef-Client-Schema-Version": EF_SCHEMA_VERSION }
  );
  if (result.efNodes.__typename === "EFNodeConnection") {
    const nodes = result.efNodes.edges.map((edge) => edge.node);
    nodesLeftToPull = result.efNodes.pageInfo.totalNodes;
    pulledNodes = nodes.length;
    try {
      await applyServerUpdateBulk(
        nodes.map((n) => ({
          id: n.id,
          nodeType: n.nodeType,
          parentId: n.parentId,
          position: n.position,
          titleText: n.titleText,
          contentText: n.contentText,
          workspaceId: n.workspaceId,
          version: n.version,
          deleted: n.deleted,
          properties: n.properties,
          tagIds: n.tagIds,
          mentionIds: n.mentionIds,
          fileIds: n.fileIds,
          rootId: n.rootId,
          referencedPageIds: n.referencedPageIds,
          clientModifiedTime: new Date(n.clientModifiedTime),
          createdTime: new Date(n.createdTime),
          modifiedTime: new Date(n.modifiedTime),
        }))
      );
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return {
        nodesLeftToPull,
        pulledNodes,
        efSchemaVersion: headers.get("X-EF-GQL-Schema-Version"),
      };
    }

    if (result.efNodes.pageInfo.endCursor) {
      await setLastServerCursor(result.efNodes.pageInfo.endCursor);
    }
  }

  debugSync("<-- pull done");
  return {
    nodesLeftToPull,
    pulledNodes,
    efSchemaVersion: headers.get("X-EF-GQL-Schema-Version"),
  };
}

async function uploadFileToS3(creds: any, fileProps: any) {
  const headersList = {
    Accept: "*/*",
  };

  const s3BucketUrl = creds.getUploadDetails[0].url;
  creds = creds.getUploadDetails[0].fields;
  if (!creds) return;

  const bodyContent = new FormData();

  const keys = Object.keys(creds);
  keys.forEach((key) => {
    bodyContent.append(key, creds[key]);
  });
  bodyContent.append("file", fileProps.fileUrl);

  await fetch(s3BucketUrl, {
    method: "POST",
    body: bodyContent,
    headers: headersList,
    mode: "no-cors",
  });
}

export async function pushFiles() {
  try {
    const files: FileNodeData[] = await db.fileQueue
      .where("uploaded")
      .equals(0) // Boolean are not indexable so we use 0 and 1
      .toArray();

    const pushFile = async (file: FileNodeData) => {
      try {
        const response = (
          await api.GetUploadDetails({
            files: {
              id: file.id,
              contentText: file.contentText,
              contentType: file.properties?.fileContentType || "",
              filename: file.properties?.fileName || "",
              tagIds: [],
              fileCreatedTime:
                new Date(file.properties?.fileCreatedTime || 0) || "",
              fileSourceDevice: window.navigator.userAgent,
              md5sum: null,
            },
          })
        ).data;

        await uploadFileToS3(response, file.properties);

        await api.FinishUpload({
          inputs: response.getUploadDetails.map((e: any) => ({
            id: e.id,
          })),
        });
        return file.id;
      } catch (e) {
        console.error("Failed to push file", e);
        return undefined;
      }
    };

    const filesPushed = (await Promise.all(files.map(pushFile))).filter(
      Boolean
    ) as string[];

    await db.transaction("readwrite", db.fileQueue, async () => {
      filesPushed.map((id) => {
        // Mark as uploaded in queue
        return db.fileQueue.update(id, {
          uploaded: 1,
        });
      });
    });
  } catch (error) {
    console.error("Failed to push files", error);
  }
}
