import { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { db } from "../db";
import { useQuery } from "../hooks/useQuery";
import { VirtualizedEditor } from "./VirtualizedEditor/VirtualizedEditor";
import { getLastVisitedInSettings } from "@/utils/settings";
import { EfNodeType } from "@/graphql";
import {
  getEditorId,
  getNodeIdFromEditorId,
} from "./VirtualizedEditor/editorUtils/genericUtils";

export const PageRouteWapper = ({ nodeType }: { nodeType: EfNodeType }) => {
  const { pageId } = useParams();
  const [thoughtpad, loading] = useQuery(
    () => db.nodes.where("nodeType").equals(EfNodeType.ThoughtPad).first(),
    [pageId]
  );
  if (nodeType !== EfNodeType.ThoughtPad) {
    return <PageRoute key={pageId} pageId={pageId} />;
  } else if (!loading) {
    return <PageRoute key={pageId} pageId={thoughtpad?.id} />;
  } else {
    return null;
  }
};

function PageRoute({ pageId = "" }: { pageId?: string }) {
  const [page, loading] = useQuery(() => db.nodes.get(pageId), [pageId]);

  const [searchParams] = useSearchParams();
  const [lastVisitedEditors, lastVisitedEditorsLoading] = useQuery(
    () => getLastVisitedInSettings(pageId),
    [pageId]
  );
  const nodeUrlParam = useMemo(() => searchParams.get("node"), []);
  const [nodeParam, setNodeParam] = useState<string>();

  const cursorInitialized = useRef(false);
  const [cursor, setCursor] = useState<string>();

  useEffect(() => {
    if (!page || lastVisitedEditorsLoading) return;
    if (cursorInitialized.current) return;
    // this code gives us the node to focus after initial load
    let focusedNode: string[] = [];
    // first priority is given to node param from URL
    if (nodeUrlParam) {
      focusedNode = [nodeUrlParam];
    } else if (Array.isArray(lastVisitedEditors)) {
      // now we have changed the data modal to have array which will be like "rootId:nodeId".
      focusedNode = lastVisitedEditors.map(
        (lastVisitedEditor) =>
          getNodeIdFromEditorId(lastVisitedEditor) as string
      );
    } else if (typeof lastVisitedEditors === "string") {
      // this is for backward compatibility where lastVisitedEditors is a string and will be just nodeId.
      focusedNode = [lastVisitedEditors];
    }

    // finding the nodes from db which are not deleted.
    db.nodes
      .where("id")
      .anyOf(focusedNode)
      .filter(({ deleted }) => !deleted)
      .toArray()
      .then((nodes) => {
        cursorInitialized.current = true;
        // taking first node which is available in the DB as cursor
        setCursor(nodes[0]?.computed.pathInPage ?? page?.computed.pathInPage);
        setNodeParam(getEditorId(page.id, nodes[0]?.id));
      });
  }, [nodeParam, page, lastVisitedEditors, lastVisitedEditorsLoading]);

  if (!cursorInitialized.current) return null;
  if (loading) return null;

  if (!page) {
    return (
      <div className="max-w-5xl mx-auto my-4">
        <div className="text-center">
          <h1 className="text-4xl font-bold">Page not found</h1>
          <p className="text-gray-500">
            The page you are looking for does not exist.
          </p>
        </div>
      </div>
    );
  }

  if (!cursor) {
    return (
      <div className="max-w-5xl mx-auto my-4">Cannot initialize cursor</div>
    );
  }

  return (
    <>
      <VirtualizedEditor
        key={page.id}
        initialCursor={cursor}
        disableTitleEdit={!!page.properties?.isSystemCreated}
        pathPrefix={page.computed.pathInPage + "/"}
        parentNode={page}
        nodeParam={nodeParam}
      />
    </>
  );
}
